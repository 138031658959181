// NOTE: railsのバージョンを上げて5.1以上になったため、yarnで追加したjqueryを使用する
//       なお、jquery-rails gemは不要となるため、追って削除する
import $ from 'jquery';

document.addEventListener("turbolinks:load", function () {
  // トグル
  $(function () {
    $('.jsAccordionTitle').on('click', function () {
      $(this).next().toggleClass('is-open');
      $(this).toggleClass('is-active');
    });

    // あらすじ用トグル
    $('.jsAccordionTitleForSynopsis').on('click', function () {
      const accordion_content = $(this).parent();

      accordion_content.toggleClass('is-open');
      $(this).toggleClass('is-active');

      if ($(this).hasClass('is-active')) {
        $(this).text('あらすじを隠す...');
        // cssクラスやmediaクエリーで指定されているものを無効化するため、0で上書きする
        $(this).css({'position': 'relative', 'padding-top': '0'});

        // transition-durationを効かせるために、autoではなくheightの値（子要素のheight合計値）を指定する
        const height = (parseFloat(accordion_content.children('.p-synopsis__movie').outerHeight(true)) || 0)
                        + (parseFloat(accordion_content.children('.p-synopsis__episode-description').outerHeight(true)) || 0)
                        + (parseFloat(accordion_content.children('.c-episode-box__title').outerHeight(true)) || 0);
        $(this).parent().css('height', height + 24); // .c-accordion-content.is-openで指定されているpadding(12px * 2)分を加算する
      } else {
        $(this).text('あらすじを見る...');
        $(this).css({'position': '', 'padding-top': ''});
        $(this).parent().css('height', '');
      }
    });

    // プロフィール用トグル
    $('.jsAccordionTitleForProfile').on('click', function () {
      const accordion_content = $(this).parent();

      accordion_content.toggleClass('is-open');
      $(this).toggleClass('is-active');

      if ($(this).hasClass('is-active')) {
        $(this).text('続きを隠す...');
        // cssクラスやmediaクエリーで指定されているものを無効化するため、0で上書きする
        $(this).css({'cssText': 'position: relative;'});
        $(this).css({'cssText': 'padding-top: 0 !important;'});

        // transition-durationを効かせるために、autoではなくheightの値（子要素のheight合計値）を指定する
        const height = (parseFloat(accordion_content.children('.p-cast-profile__description').outerHeight(true)) || 0)
                        + (parseFloat(accordion_content.children('.c-profile-box__title').outerHeight(true)) || 0);
        $(this).parent().css('height', height + 12); // .c-accordion-content.is-openで指定されているpadding(12px)分を加算する
      } else {
        $(this).text('続きを見る...');
        $(this).css({'position': '', 'padding-top': ''});
        $(this).parent().css('height', '');
      }
    });
  });
});