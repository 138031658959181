// NOTE: railsのバージョンを上げて5.1以上になったため、yarnで追加したjqueryを使用する
//       なお、jquery-rails gemは不要となるため、追って削除する
import $ from 'jquery';

// jQuery(function ($) {
// window.onload = function () {
document.addEventListener('turbolinks:load', function () {
  // ハンバーガー
  $(function () {
    $('.js-hamburger').click(function () {
      $('body').toggleClass('is-drawerActive')

      if ($(this).attr('aria-expanded') == 'false') {
        $(this).attr('aria-expanded', 'true')
        $('.js-global-menu').attr('aria-hidden', 'false')
      } else {
        $(this).attr('aria-expanded', 'false')
        $('.js-global-menu').attr('aria-hidden', 'true')
      }
    })

    $('.c-sitemap__link').click(function () {
      $('body').removeClass('is-drawerActive')
      $('.js-hamburger').attr('aria-expanded', 'false')
      $('.js-global-menu').attr('aria-hidden', 'true')
    })
    $('#js-drawer-background').click(function () {
      $('body').removeClass('is-drawerActive')
      $('.js-hamburger').attr('aria-expanded', 'false')
      $('.js-global-menu').attr('aria-hidden', 'true')
    })
  })
})
// };

// });
