// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('jquery')

require('user/hamburger.js')
require('user/accordion.js')
// import '../src/application.scss'

// import 'lazysizes';

// babel関連（ES6の記述を使える様にするため）
// ref → https://qiita.com/kazutosato/items/9d12d9402dedbc2ac896）
import 'core-js/stable'
import 'regenerator-runtime/runtime'
